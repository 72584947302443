import logo from './logo.svg';
import './App.css';
import Home from './pages/home/Home';
import Home2 from './pages/home/Home2';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import About from './pages/about/About';
import Terms from './pages/terms/Terms';
import Privacy from './pages/privacy/Privacy';
import Product from './pages/product/Product';
import Product1 from './pages/products/Product1';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Header />

      <BrowserRouter>
          <div>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/home2' element={<Home2 />} />
              <Route path='/about' element={<About />} />
              <Route path='/terms' element={<Terms />} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='/product/:slug' element={<Product />} />
              <Route path='/product1' element={<Product1 />} />
            </Routes>
          </div>
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;
