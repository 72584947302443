import './Home.css';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import data from '../../data.json';
import sketch from '../../assets/images/me-sketch.jpg';

function Home2() {
    const [featured, setFeatured] = useState({});
    const [allProducts, setAllProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(0);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [page, setPage] = useState(null);

    useEffect(() => {
        let res = window
          .fetch(`http://localhost:1337/api/articles`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authenticate the request
              Authorization: "Bearer " + process.env.REACT_APP_STRAPI_API_KEY,
            },
            // send the GraphQL query
          })
          .then((response) => response.json())
          .then(({ data, errors }) => {
            if (errors) {
              console.error(errors);
            }
    
            console.log('results b', data);
          //  data.productCollection.items.sort(compareFn);
          //  setAllProducts(data.productCollection.items);
          //  setProducts(data.productCollection.items);
            // rerender the entire component with new data
           // setPage(data.pageCollection.items[0]);
            //setProducts(data.pr)
          });

         // console.log('res', res);
    }, []);

    useEffect(() => {
       // setProducts(data.products);

        if (data.products.length > 0){
            let _categories = [];
            _categories = data.products.map(item => item.category);
            setCategories(_categories);
        }
    }, []);

    useEffect(() => {
        const query = `
{
  productCollection {
    items {
      productId
      name
      description
      longDescription2
      price
      longDescription{
        json
      }
      affiliateUrl
      category
      thumbnail {
        url
      }
    }
  }
}
`;
        window
          .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACEID}/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authenticate the request
              Authorization: "Bearer " + process.env.REACT_APP_CONTENTFUL_ACCESSTOKEN,
            },
            // send the GraphQL query
            body: JSON.stringify({ query }),
          })
          .then((response) => response.json())
          .then(({ data, errors }) => {
            if (errors) {
              console.error(errors);
            }
    
            console.log('results', data);
            data.productCollection.items.sort(compareFn);
            setAllProducts(data.productCollection.items);
            setProducts(data.productCollection.items);
            // rerender the entire component with new data
           // setPage(data.pageCollection.items[0]);
            //setProducts(data.pr)
          });
      }, []);

    useEffect(() => {
        if (products.length > 0){
            setFeatured(products[0]);
        }
    }, [products]);

    useEffect(() => {
        if (selectedCategory !== ''){
            let _products = allProducts.filter(item => item.category === selectedCategory);
            setProducts(_products);
        }

        else{
            setProducts(allProducts);
        }
    }, [selectedCategory]);

    function compareFn(a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        // a must be equal to b
        return 0;
      }

    function handleDetailsClick(item){
        setCurrentProduct(item);
        setShowDetails(true);
    }

    function handleCloseClick(){
        setShowDetails(false);
    }

    function handleCategoryClick(item){
        if (item !== 'All')
            setSelectedCategory(item);
        else
            setSelectedCategory('');
    }

    return (
        <>
        <div className={`home ${showDetails?'blur': ''}`}>
            <div className='home__categories'>
                {categories.map(item => (
                    <div className='home__category' onClick={() => handleCategoryClick(item)}>
                        {item}
                    </div>
                ))}

                <div className='home__category' onClick={() => handleCategoryClick('All')}>All</div>
            </div>

            <div className='home__blurb' style={{display:"none"}}>
                Welcome to edcgear.dev 💻! This site is for all those Developers, Programmers, Engineers and the like who fill their pockets and backpacks with gadgets of all sorts 🖱️. Everything that I list on this site, I own and use personally on a daily basis 🎒 and I only showcase things that I find useful for my day to day job a a programmer.

                <div className='home__blurb-signature'>-ThatSoftwareDude</div>
            </div>

            <div className='home__disclosure'>
            * edcgear.dev is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com. It really does help keep the servers running! 
            </div>

            <div className='home__hero' style={{display:"block"}}>
                <div className='home__hero-blurb'>
                    <div className='home__hero-blurb-large'>EDC-GEAR</div>
                    <div className='home__hero-blurb-small'>Everyday Carry for Developers
                    <span>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9.5 42q-.65 0-1.075-.425Q8 41.15 8 40.5V38H6v-7.5q0-.65.425-1.075Q6.85 29 7.5 29H10V13.5q0-3.2 2.35-5.35Q14.7 6 18 6q3.15 0 5.325 2.175Q25.5 10.35 25.5 13.5v21q0 1.9 1.3 3.2Q28.1 39 30 39q2.05 0 3.525-1.275T35 34.5V19h-2.5q-.65 0-1.075-.425Q31 18.15 31 17.5V10h2V7.5q0-.65.425-1.075Q33.85 6 34.5 6h4q.65 0 1.075.425Q40 6.85 40 7.5V10h2v7.5q0 .65-.425 1.075Q41.15 19 40.5 19H38v15.5q0 3.2-2.35 5.35Q33.3 42 30 42q-3.15 0-5.325-2.175Q22.5 37.65 22.5 34.5v-21q0-1.9-1.3-3.2Q19.9 9 18 9q-2.05 0-3.525 1.275T13 13.5V29h2.5q.65 0 1.075.425Q17 29.85 17 30.5V38h-2v2.5q0 .65-.425 1.075Q14.15 42 13.5 42Z"/></svg>
                    </span></div>
                </div>

                <div className='home__hero-feature-product' style={{display:"none"}}>
                    <div className='home__product'>
                        <div className='home__product-name'>
                        {featured.name}
                        </div>

                        <div className='home__product-description'>
                            {featured.description}
                        </div>

                        <div className='home__product-btn'>
                            <button className='btn btn--primary'>Details</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='home2__products-wrapper'>
            <div className='home2__products'>
                {products.map(item => (
                    <div className='home2__product'>
                        <div className='home2__product-thumbnail' onClick={() => handleDetailsClick(item)}>
                            <img src={`${item.thumbnail.url}`} loading="lazy" />
                        </div>

                        <div className='home2__product-info'>
                            <div className='home2__product-category'>{item.category}</div>

                            <div className='home2__product-name'>
                            {item.name}
                            </div>

                            <div className='home2__product-description'>
                                {item.description}
                            </div>

                            <div className='home2__product-btn'>
                                <button className='btn btn--primary' onClick={() => handleDetailsClick(item)}>Details</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className='home2__right-side'>
                    sfasdf
            </div>
            </div>
        </div>

        {showDetails && (
            <>
                <div className='overlay'></div>

                <div className='modal modal__product_wrapper'>
                    <div className='modal__product'>
                        <div className='modal__product-thumbnail'>
                            <img src={`${currentProduct.thumbnail.url}`} alt='' />
                        </div>

                        <div className='modal__product-details'>
                            <div className='modal__product-details-name'>
                                {currentProduct.name}
                            </div>
                            
                            <div className='modal__product-details-description'>
                                <div>
                                    <ReactMarkdown>
                                        {currentProduct.longDescription2}
                                    </ReactMarkdown>
                                </div>

                                {/* <h2>Hello</h2>
                                {currentProduct.longDescription2}
                                {currentProduct.longDescription && (
                                <div>
                                <h2>Description</h2>
                                {currentProduct.longDescription.json.content[0].content[0].value}
                                </div>
                                )} */}
                            </div>
                        </div>
                    </div>

                    <div className='modal__product-details-buttons'>
                            <a href={currentProduct.affiliate_url} target="_blank" className='btn btn--primary'>Purchase on Amazon</a>
                        </div>

                    <div className='modal__product-close'>
                        <button className='btn btn--close' onClick={() => handleCloseClick()}>Back to stuff ➡️</button>
                    </div>
                </div>
            </>
        )}
        </>
    )
}

export default Home2;