import './Header.css';
import headerimage from '../../assets/images/header.png';

function Header() {

    return (
        <div className='header'>
            <div className='header__logo'>

                <span className='header__logo-name'>
                    <a href='/'>EDCGear.dev</a>
                </span>
            </div>

            <div className='header__links'>
                <a href='/about'>About</a>
                <a href=''>Collections</a>
            </div>
        </div>
    )
}

export default Header;