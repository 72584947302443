import './About.css';
import sketch from '../../assets/images/me-sketch.jpg';

function About() {

    return (
        <div className='about'>
            <div className='about__header'>Welcome to EDCGear.dev</div>

            <p>
                This site is owned and operated by Walt over at <a href="https://www.thatsoftwaredude.com">thatsoftwaredude.com</a>. 
            </p>

            <p>
                It features EDC (Everyday Carry) items that programmers, developers and really anybody interested in tech would find interesting.
                Instead of wallets, knives and multitools though, you will find SSD drives, cables and power banks.
            </p>

            <p>
                New items are constantly getting added so check frequently and you might find the next thing to toss into your tech bag on the way to work.
            </p>

            <p>
                - Walt
            </p>
        </div>
    )
}

export default About;