import { useEffect } from 'react';
import './Product.css';

function Product() {
    useEffect(() => {
        const query = `
        {
          productCollection {
            items {
              productId
              name
              description
              longDescription{
                json
              }
              affiliateUrl
              category
              thumbnail {
                url
              }
            }
          }
        }
        `;

        window
          .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACEID}/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authenticate the request
              Authorization: "Bearer " + process.env.REACT_APP_CONTENTFUL_ACCESSTOKEN,
            },
            // send the GraphQL query
            body: JSON.stringify({ query }),
          })
          .then((response) => response.json())
          .then(({ data, errors }) => {
            if (errors) {
              console.error(errors);
            }
    
            console.log('results', data);
            //setProducts(data.productCollection.items);
            // rerender the entire component with new data
           // setPage(data.pageCollection.items[0]);
            //setProducts(data.pr)
          });
    }, []);

    return (
        <>
        <div className='overlay'></div>

        <div className='modal modal__product_wrapper'>
            {/* <div className='modal__product'>
                <div className='modal__product-thumbnail'>
                    <img src={`${currentProduct.thumbnail.url}`} alt='' />
                </div>

                <div className='modal__product-details'>
                    <div className='modal__product-details-name'>
                        {currentProduct.name}
                    </div>
                    
                    <div className='modal__product-details-description'>
                        <h2>Description</h2>
                        {currentProduct.description}
                        {currentProduct.longDescription.json.content[0].content[0].value}
                    </div>

                    <div className='modal__product-details-buttons'>
                        <a href={currentProduct.affiliate_url} target="_blank" className='btn btn--primary'>${currentProduct.price} on Amazon</a>
                    </div>
                </div>
            </div> */}

            {/* <div className='modal__product-close'>
                <button className='btn btn--close' onClick={() => handleCloseClick()}>Back to stuff ➡️</button>
            </div> */}
        </div>
    </>
    )
}

export default Product;