import './Footer.css';

function Footer() {

    return (
        <div className='footer'>
            <div className='footer__links'>
                <div><a href='/terms'>Terms</a></div>
                <div><a href='/privacy'>Privacy</a></div>
            </div>

            <div className='footer__disclosure'>
This site is intended for informational and entertainment purposes only. The contents are the views and opinions of the author(s) and/or associates. All products and trademarks are the property of their respective owners. Reproduction in whole or in part, in any form or medium, without express written permission of edcgear.dev,  is prohibited. All content and graphical elements are Copyright &amp; 2022. 
All rights reserved. 

            </div>
        </div>
    )
}

export default Footer;