import './Privacy.css';

function Privacy() {

    return (
        <div class="privacy">
        <h2>Privacy Policy</h2>
<p>
edcgear.dev operates edcgear.dev and may operate other websites. It is edcgear.dev's policy to respect your privacy regarding any information we may collect while operating our websites.
</p>

<h2>Website Visitors</h2>
<p>
Like most website operators, edcgear.dev collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. edcgear.dev's purpose in collecting non-personally identifying information is to better understand how edcgear.dev's visitors use its website. From time to time, edcgear.dev may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
</p>

<p>
edcgear.dev also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on edcgear.dev blogs/sites. edcgear.dev only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below, except that commenter IP addresses and email addresses are visible and disclosed to the administrators of the blog/site where the comment was left.
</p>

<h2>Gathering of Personally-Identifying Information</h2>
<p>
Certain visitors to edcgear.dev's websites choose to interact with edcgear.dev in ways that require edcgear.dev to gather personally-identifying information. The amount and type of information that edcgear.dev gathers depend on the nature of the interaction. For example, we ask visitors who sign up at thatoftwaredude.com to provide a username and email address. Those who engage in transactions with edcgear.dev are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, edcgear.dev collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with edcgear.dev. edcgear.dev does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.
</p>

<h2>Aggregated Statistics</h2>
<p>
edcgear.dev may collect statistics about the behavior of visitors to its websites. edcgear.dev may display this information publicly or provide it to others. However, edcgear.dev does not disclose personally-identifying information other than as described below.
</p>

<h2>Protection of Certain Personally-Identifying Information</h2>
<p>
edcgear.dev discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on edcgear.dev's behalf or to provide services available at edcgear.dev's websites, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using edcgear.dev's websites, you consent to the transfer of such information to them. edcgear.dev will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, edcgear.dev discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when edcgear.dev believes in good faith that disclosure is reasonably necessary to protect the property or rights of edcgear.dev, third parties or the public at large. If you are a registered user of an edcgear.dev website and have supplied your email address, edcgear.dev may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with edcgear.dev and our products. If you send us a request (for example via email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. edcgear.dev takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
</p>

<h2>Cookies</h2>
<p>
A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. edcgear.dev uses cookies to help edcgear.dev identify and track visitors, their usage of edcgear.dev website, and their website access preferences. edcgear.dev visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using edcgear.dev's websites, with the drawback that certain features of edcgear.dev's websites may not function properly without the aid of cookies.
</p>

<h2>Business Transfers</h2>
<p>
If edcgear.dev, or substantially all of its assets, were acquired, or in the unlikely event that edcgear.dev goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur and that any acquirer of edcgear.dev may continue to use your personal information as set forth in this policy.
</p>

<h2>Ads</h2>
<p>
Ads appearing on any of our websites may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by edcgear.dev and does not cover the use of cookies by any advertisers.
</p>

<h2>Affiliate Marketing</h2>
        <p>
            We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.
        </p>

        <p>
            edcgear.dev is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.com.
        </p>

<h2>Privacy Policy Changes</h2>
<p>
Although most changes are likely to be minor, edcgear.dev may change its Privacy Policy from time to time, and in edcgear.dev's sole discretion. edcgear.dev encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
</p>
    </div>
    )
}

export default Privacy;